import logo from './logo.svg';
import './App.css';
import { Button, Jumbotron, Nav, Card, Row, Col } from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


function Header() {
  return (
    <Jumbotron>
      <Row>
        <Col>
          <img src={logo} className="App-logo" alt="logo" />
        </Col>
        <Col>
          <h1>Hello, world!</h1>
          <p>
            Welcome to njc46.dev
          </p>
          <p>
            <Link to="/about">
              <Button variant="primary">Learn more</Button>
            </Link>
          </p>
        </Col>
      </Row>
    </Jumbotron>
    )
}


function Home() {
  return (
    <Row>
      <Col>
        <Header />
      </Col>
    </Row>
  );
}

function About() {
  return (
      <Jumbotron>
        <Row>
          <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src={logo} />
            <Card.Body>
              <Card.Title>Orange Quarry</Card.Title>
              <Card.Text>
                Orange Quarry is a great thing
              </Card.Text>
              <Link to="/">
                <Button variant="primary">Home</Button>
              </Link>
            </Card.Body>
          </Card>
          </Col>
        </Row>
      </Jumbotron>
    )
}

function Users() {
  return <h2>Users</h2>;
}

function App() {
    return (
      <Router>
        <div>
          <Nav fill variant="tabs">
            <Nav.Item>
              <Nav.Link href="/">Home</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/about">About</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/users">User</Nav.Link>
            </Nav.Item>
          </Nav>
          <Switch>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/users">
              <Users />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </Router>
      )
}
export default App;
